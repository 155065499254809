import Axios from 'axios'

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    permissions: []
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    },
    getPermissions (state, payload) {
      state.permissions = payload
    }
  },
  actions: {
    login ({ commit }, loginData) {
      // const userCopy = JSON.parse(JSON.stringify(user))
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Axios({ url: `/api/account/login`, data: loginData, method: 'POST' })
          .then(response => {
            const token = response.data.token
            const user = response.data.user
            localStorage.setItem('token', token)
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            commit('auth_success', token, user)
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete Axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    getPermissions ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getAllPathAccessRules()
          .then(response => {
            commit('getPermissions', response.data)
            resolve()
          }, error => {
            reject(error.data)
          })
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getToken: state => state.token,
    getPermissions: state => state.permissions
  }
}
