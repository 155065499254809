export default {
  state: {
    eventTypes: [],
    statusTypes: [],
    returnTypes: [],
    warehouseStateTypes: [],
    canceledLineReasonTypes: [],
    canceledLineFaultTypes: [],
    canceledLineCommentTypes: [],
    userRoleTypes: [],
    canceledLineApproveTypes: [],
    canceledLinesWhsTypes: [],
    canceledLinesFirmIdTypes: [],
    canceledLinesOwnTypes: [],
    problemsOwnTypes: [],
    problemsBranchOfficeTypes: [],
    delaysBranchOfficeTypes: [],
    carrierTypes: [],
    eventsOwnTypes: [],
    eventNonconformityReasonTypes: [],
    eventNonconformityTypes: [],
    eventDelayTypes: [],
    eventFaultTypes: [],
    informedPersonTypes: [],
    languageTypes: [],
    categoryTypes: [],
    owners: [],
    countryTypes: [],
    accessibleCountryTypes: [],
    openCommentsWindow: [],
    departmentTypes: [],
    activeDepartmentTypes: [],
    defaultDepartmentTypes: [],
    departmentTypesByUserLanguage: [],
    decisionTypes: [],
    responsibilityTypes: [],
    users: [],
    priorityTypes: [],
    rowStatusTypes: [],
    saleGroupsTypes: [],
    flagTypes: [],
    allOwners: [],
    userGroups: [],
    user: {},
    activities: [],
    branches: [],
    eventNonconformityTypesByUserGroup: [],
    warehouses: [],
    inboundActReasonTypes: [],
    qualityAssessmentTypes: [],
    productStates: [],
    inboundActCarriers: [],
    suppliers: []
  },
  mutations: {
    fetchOwners (state, payload) {
      state.owners = payload
    },
    fetchCountryTypes (state, payload) {
      state.countryTypes = payload
    },
    fetchAccessibleCountryTypes (state, payload) {
      state.accessibleCountryTypes = payload
    },
    fetchInformedPersonTypes (state, payload) {
      state.informedPersonTypes = payload
    },
    fetchCategoryTypes (state, payload) {
      state.categoryTypes = payload
    },
    fetchEventTypes (state, payload) {
      state.eventTypes = payload
    },
    fetchEventNonconformityTypes (state, payload) {
      state.eventNonconformityTypes = payload
    },
    fetchEventNonconformityReasonTypes (state, payload) {
      state.eventNonconformityReasonTypes = payload
    },
    fetchEventDelayTypes (state, payload) {
      state.eventDelayTypes = payload
    },
    fetchStatusTypes (state, payload) {
      state.statusTypes = payload
    },
    fetchReturnTypes (state, payload) {
      state.returnTypes = payload
    },
    fetchWarehouseStateTypes (state, payload) {
      state.warehouseStateTypes = payload
    },
    fetchCanceledLineReasonTypes (state, payload) {
      state.canceledLineReasonTypes = payload
    },
    fetchCanceledLineFaultTypes (state, payload) {
      state.canceledLineFaultTypes = payload
    },
    fetchEventFaultTypes (state, payload) {
      state.eventFaultTypes = payload
    },
    fetchCanceledLineCommentTypes (state, payload) {
      state.canceledLineCommentTypes = payload
    },
    fetchUserRoleTypes (state, payload) {
      state.userRoleTypes = payload
    },
    fetchCanceledLineApproveTypes (state, payload) {
      state.canceledLineApproveTypes = payload
    },
    fetchCanceledLinesWhsTypes (state, payload) {
      state.canceledLinesWhsTypes = payload
    },
    fetchCanceledLinesFirmIdTypes (state, payload) {
      state.canceledLinesFirmIdTypes = payload
    },
    fetchCanceledLinesOwnTypes (state, payload) {
      state.canceledLinesOwnTypes = payload
    },
    fetchProblemsOwnTypes (state, payload) {
      state.problemsOwnTypes = payload
    },
    fetchProblemsBranchOfficeTypes (state, payload) {
      state.problemsBranchOfficeTypes = payload
    },
    fetchDelaysBranchOfficeTypes (state, payload) {
      state.delaysBranchOfficeTypes = payload
    },
    fetchCarrierTypes (state, payload) {
      state.carrierTypes = payload
    },
    fetchEventsOwnTypes (state, payload) {
      state.eventsOwnTypes = payload
    },
    fetchLanguageTypes (state, payload) {
      state.languageTypes = payload
    },
    fetchOpenCommentsWindow (state, payload) {
      state.openCommentsWindow = payload
    },
    fetchDepartmentTypes (state, payload) {
      state.departmentTypes = payload
    },
    fetchActiveDepartmentTypes (state, payload) {
      state.activeDepartmentTypes = payload
    },
    fetchDefaultDepartmentTypes (state, payload) {
      state.defaultDepartmentTypes = payload
    },
    fetchDepartmentTypesByUserLanguage (state, payload) {
      state.departmentTypesByUserLanguage = payload
    },
    fetchDecisionTypes (state, payload) {
      state.decisionTypes = payload
    },
    fetchResponsibilityTypes (state, payload) {
      state.responsibilityTypes = payload
    },
    fetchUsers (state, payload) {
      state.users = payload
    },
    fetchPriorityTypes (state, payload) {
      state.priorityTypes = payload
    },
    fetchRowStatusTypes (state, payload) {
      state.rowStatusTypes = payload
    },
    fetchSaleGroupsTypes (state, payload) {
      state.saleGroupsTypes = payload
    },
    fetchFlagTypes (state, payload) {
      state.flagTypes = payload
    },
    fetchAllOwners (state, payload) {
      state.allOwners = payload
    },
    fetchUserGroups (state, payload) {
      state.userGroups = payload
    },
    fetchUser (state, payload) {
      state.user = payload
    },
    fetchActivities (state, payload) {
      state.activities = payload
    },
    fetchBranches (state, payload) {
      state.branches = payload
    },
    fetchNonconformityTypesByUserGroup (state, payload) {
      state.eventNonconformityTypesByUserGroup = payload
    },
    fetchWarehouses (state, payload) {
      state.warehouses = payload
    },
    fetchInboundActReasonTypes (state, payload) {
      state.inboundActReasonTypes = payload
    },
    fetchQualityAssessmentTypes (state, payload) {
      state.qualityAssessmentTypes = payload
    },
    fetchProductStates (state, payload) {
      state.productStates = payload
    },
    fetchInboundActCarriers (state, payload) {
      state.inboundActCarriers = payload
    },
    fetchSuppliers (state, payload) {
      state.suppliers = payload
    }
  },
  actions: {
    fetchGetOwners ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getClsOwners().then(result => {
          commit('fetchOwners', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCountryTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCountryTypes().then(result => {
          commit('fetchCountryTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetAccessibleCountryTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getAccessibleCountryTypes().then(result => {
          commit('fetchAccessibleCountryTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetInformedPersonTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getInformedPersonTypes().then(result => {
          commit('fetchInformedPersonTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCategoryTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCategoryTypes().then(result => {
          commit('fetchCategoryTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventTypes().then(result => {
          commit('fetchEventTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventNonconformityTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventNonconformityTypes().then(result => {
          commit('fetchEventNonconformityTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventNonconformityReasonTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventNonconformityReasonTypes().then(result => {
          commit('fetchEventNonconformityReasonTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventDelayTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventDelayTypes().then(result => {
          commit('fetchEventDelayTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetStatusTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getStatusTypes().then(result => {
          commit('fetchStatusTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetReturnTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getReturnTypes().then(result => {
          commit('fetchReturnTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetWarehouseStateTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getWarehouseStateTypes().then(result => {
          commit('fetchWarehouseStateTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLineReasonTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLineReasonTypes().then(result => {
          commit('fetchCanceledLineReasonTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLineFaultTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLineFaultTypes().then(result => {
          commit('fetchCanceledLineFaultTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventFaultTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventFaultTypes().then(result => {
          commit('fetchEventFaultTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLineCommentTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLineCommentTypes().then(result => {
          commit('fetchCanceledLineCommentTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetUserRoleTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getUsersRoles().then(result => {
          commit('fetchUserRoleTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLineApproveTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLineApproveTypes().then(result => {
          commit('fetchCanceledLineApproveTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLinesWhsTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLinesWhsTypesList().then(result => {
          commit('fetchCanceledLinesWhsTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLinesFirmIdTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLinesFirmIdTypesList().then(result => {
          commit('fetchCanceledLinesFirmIdTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLinesOwnTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLinesOwnTypesList().then(result => {
          commit('fetchCanceledLinesOwnTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetProblemsOwnTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getProblemsOwnTypesList().then(result => {
          commit('fetchProblemsOwnTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetProblemsBranchOfficeTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getProblemsBranchOfficeTypesList().then(result => {
          commit('fetchProblemsBranchOfficeTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetDelaysBranchOfficeTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDelaysBranchOfficeTypesList().then(result => {
          commit('fetchDelaysBranchOfficeTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCarrierTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCarrierTypes().then(result => {
          commit('fetchCarrierTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetEventsOwnTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getEventsOwnTypesList().then(result => {
          commit('fetchEventsOwnTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetLanguageTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getLanguageTypes().then(result => {
          commit('fetchLanguageTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetOpenCommentsWindow ({ commit }, data) {
      commit('fetchOpenCommentsWindow', data)
    },
    fetchGetDepartmentTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDepartmentTypes().then(result => {
          commit('fetchDepartmentTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetActiveDepartmentTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getActiveDepartmentTypes().then(result => {
          commit('fetchActiveDepartmentTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetDefaultDepartmentTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDefaultDepartmentTypes().then(result => {
          commit('fetchDefaultDepartmentTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetDepartmentTypesByUserLanguage ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDepartmentTypesByUserLanguage().then(result => {
          commit('fetchDepartmentTypesByUserLanguage', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetDecisionTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDecisionTypes().then(result => {
          commit('fetchDecisionTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetResponsibilityTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getResponsibilityTypes().then(result => {
          commit('fetchResponsibilityTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getFullNameAndIdPairList().then(result => {
          commit('fetchUsers', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetPriorityTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getPriorityTypes().then(result => {
          commit('fetchPriorityTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetRowStatusTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getRowStatusTypes().then(result => {
          commit('fetchRowStatusTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetSaleGroupsTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getClsSaleGrp().then(result => {
          commit('fetchSaleGroupsTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetFlagTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getFlagsTypes().then(result => {
          commit('fetchFlagTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetAllOwners ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getAllOwners().then(result => {
          commit('fetchAllOwners', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetUserGroups ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getUserGroupsKeyToValue().then(result => {
          commit('fetchUserGroups', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetUser ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getUser().then(result => {
          commit('fetchUser', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetActivities ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getAllActivities().then(result => {
          commit('fetchActivities', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetBranches ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getClsBranches().then(result => {
          commit('fetchBranches', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetNonconformityTypesByUserGroup ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getNonconformityTypesByUserGroup().then(result => {
          commit('fetchNonconformityTypesByUserGroup', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetWarehouses ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getClsStockDesc().then(result => {
          commit('fetchWarehouses', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetInboundActReasonTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getInboundActReasonTypes().then(result => {
          commit('fetchInboundActReasonTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetQualityAssessmentTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getQualityAssessmentTypes().then(result => {
          commit('fetchQualityAssessmentTypes', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetProductStates ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getProductStates().then(result => {
          commit('fetchProductStates', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetInboundActCarriers ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getInboundActCarriers().then(result => {
          commit('fetchInboundActCarriers', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetSuppliers ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getSuppliers().then(result => {
          commit('fetchSuppliers', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    }
  },
  getters: {
    fetchOwners: state => state.owners,
    fetchCountryTypes: state => state.countryTypes,
    fetchAccessibleCountryTypes: state => state.accessibleCountryTypes,
    fetchEventTypes: state => state.eventTypes,
    fetchInformedPersonTypes: state => state.informedPersonTypes,
    fetchEventNonconformityTypes: state => state.eventNonconformityTypes,
    fetchEventNonconformityReasonTypes: state => state.eventNonconformityReasonTypes,
    fetchStatusTypes: state => state.statusTypes,
    fetchReturnTypes: state => state.returnTypes,
    fetchEventDelayTypes: state => state.eventDelayTypes,
    fetchEventFaultTypes: state => state.eventFaultTypes,
    fetchWarehouseStateTypes: state => state.warehouseStateTypes,
    fetchCanceledLineReasonTypes: state => state.canceledLineReasonTypes,
    fetchCanceledLineFaultTypes: state => state.canceledLineFaultTypes,
    fetchCanceledLineCommentTypes: state => state.canceledLineCommentTypes,
    fetchUserRoleTypes: state => state.userRoleTypes,
    fetchCanceledLineApproveTypes: state => state.canceledLineApproveTypes,
    fetchCanceledLinesWhsTypes: state => state.canceledLinesWhsTypes,
    fetchCanceledLinesFirmIdTypes: state => state.canceledLinesFirmIdTypes,
    fetchCanceledLinesOwnTypes: state => state.canceledLinesOwnTypes,
    fetchProblemsOwnTypes: state => state.problemsOwnTypes,
    fetchProblemsBranchOfficeTypes: state => state.problemsBranchOfficeTypes,
    fetchDelaysBranchOfficeTypes: state => state.delaysBranchOfficeTypes,
    fetchCarrierTypes: state => state.carrierTypes,
    fetchEventsOwnTypes: state => state.eventsOwnTypes,
    fetchLanguageTypes: state => state.languageTypes,
    fetchCategoryTypes: state => state.categoryTypes,
    fetchOpenCommentsWindow: state => state.openCommentsWindow,
    fetchDepartmentTypes: state => state.departmentTypes,
    fetchActiveDepartmentTypes: state => state.activeDepartmentTypes,
    fetchDefaultDepartmentTypes: state => state.defaultDepartmentTypes,
    fetchDepartmentTypesByUserLanguage: state => state.departmentTypesByUserLanguage,
    fetchDecisionTypes: state => state.decisionTypes,
    fetchResponsibilityTypes: state => state.responsibilityTypes,
    fetchUsers: state => state.users,
    fetchPriorityTypes: state => state.priorityTypes,
    fetchRowStatusTypes: state => state.rowStatusTypes,
    fetchSaleGroupsTypes: state => state.saleGroupsTypes,
    fetchFlagTypes: state => state.flagTypes,
    fetchAllOwners: state => state.allOwners,
    fetchUserGroups: state => state.userGroups,
    fetchUser: state => state.user,
    fetchActivities: state => state.activities,
    fetchBranches: state => state.branches,
    fetchNonconformityTypesByUserGroup: state => state.eventNonconformityTypesByUserGroup,
    fetchWarehouses: state => state.warehouses,
    fetchInboundActReasonTypes: state => state.inboundActReasonTypes,
    fetchQualityAssessmentTypes: state => state.qualityAssessmentTypes,
    fetchProductStates: state => state.productStates,
    fetchInboundActCarriers: state => state.inboundActCarriers,
    fetchSuppliers: state => state.suppliers
  }
}
