export default {
  methods: {
    handleLoadError (payload, description) {
      this.$toast.error({ title: 'Error', message: payload.response.data })
    },
    handleLoadSuccess (payload) {
      this.$toast.success({ title: 'Success', message: 'Data loaded successfully' })
    }
  }
}
