import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from './'
// import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: '1', // EN
  save: process.env.NODE_ENV === 'development',
  languages: [
    // EN
    new MLanguage('1').create({
      'Version': 'Version',
      'Sign In': 'Sign In',
      'All rights reserved': 'All rights reserved',
      'Copyright': 'Copyright',
      'Logged': 'Logged',
      'Comments': 'Comments',
      'Whs': 'Whs',
      'Owner': 'Owner',
      'FirmId': 'FirmId',
      'POSID': 'POSID',
      'Pr Code': 'Pr. Code',
      'Product': 'Product',
      'Supp': 'Supp.',
      'Reason': 'Reason',
      'Fault': 'Fault',
      'Client': 'Client',
      'Deliv Date': 'Deliv. Date',
      'Picked': 'Picked',
      'Ordered': 'Ordered',
      'Diff Qty': 'Diff. Qty.',
      'Pck list': 'Pck. list',
      'Approved': 'Approved',
      'Client Address': 'Client Address',
      'Public Canceled Lines': 'Public Canceled Lines',
      'Comment': 'Comment',
      'Order No': 'Order No',
      'Document No': 'Document No',
      'Manager': 'Manager',
      'Activity': 'Activity',
      'EAN': 'EAN',
      'Diff %': 'Diff %',
      'Agent': 'Agent',
      'Tvnt': 'Tvnt',
      'Agent Email': 'Agent Email',
      'Supp Pr Code': 'Supp Pr Code',
      'Logged at': 'Logged at',
      'Yes': 'Yes',
      'No': 'No',
      'Report': 'Report',
      'Public Problems': 'Public Problems',
      'Public Problems list': 'Public Problems list',
      'Countries': 'Countries',
      'Serial No': 'Serial No',
      'Created At': 'Created At',
      'Customer': 'Customer',
      'Address': 'Address',
      'Product code': 'Code',
      'Product Name': 'Product Name',
      'Quantity': 'Quantity',
      'Problem': 'Problem',
      'Decision': 'Decision',
      'To Department': 'To Department',
      'Sale Group': 'Sale Group',
      'Source': 'Source',
      'Is confirmed': 'Is confirmed'
    }),
    // LT
    new MLanguage('2').create({
      'Version': 'Versija',
      'Sign In': 'Prisijungti',
      'All rights reserved': 'Visos teisės saugomos',
      'Copyright': 'Autorinės teisės',
      'Public Canceled Lines': 'Vieši išbraukymai',
      'Logged': 'Pažymėta',
      'Deliv Date': 'Prist. data',
      'Whs': 'Sand.',
      'Owner': 'Savininkas',
      'FirmId': 'FirmId',
      'POSID': 'POSID',
      'Client': 'Klientas',
      'Client Address': 'Kliento adresas',
      'Pr Code': 'Pr. kodas',
      'Product': 'Prekė',
      'Supp': 'Tiek.',
      'Picked': 'Priimta',
      'Ordered': 'Užsakyta',
      'Diff Qty': 'Skirtumas',
      'Pck list': 'Pck. sąraš.',
      'Reason': 'Priežastis',
      'Fault': 'Klaida',
      'Comments': 'Komentarai',
      'Approved': 'Patvirtinta',
      'Comment': 'Komentaras',
      'Order No': 'Užsakymo Nr.',
      'Document No': 'Dokumento Nr.',
      'Manager': 'Vadybininkas',
      'Activity': 'Veikla',
      'EAN': 'EAN',
      'Diff %': 'Skirt %',
      'Agent': 'Agentas',
      'Tvnt': 'Tvnt',
      'Agent Email': 'Agento e-paštas',
      'Supp Pr Code': 'Tiek Pr Kodas',
      'Logged at': 'Įrašyta',
      'Yes': 'Taip',
      'No': 'Ne',
      'Report': 'Ataskaitos',
      'Public Problems': 'Viešos problemos',
      'Public Problems list': 'Viešų problemų sąrašas',
      'Countries': 'Šalys',
      'Serial No': 'Serijos nr.',
      'Created At': 'Sukurta',
      'Customer': 'Klientas',
      'Address': 'Adresas',
      'Product code': 'Kodas',
      'Product Name': 'Produkto pavadinimas',
      'Quantity': 'Kiekis',
      'Problem': 'Problema',
      'Decision': 'Sprendimas',
      'To Department': 'Departamentui',
      'Sale Group': 'Veikla',
      'Source': 'Šaltinis',
      'Is confirmed': 'Ar patvirtinta'
    }),
    // LV
    new MLanguage('3').create({
      'Version': 'Versija',
      'Sign In': 'Lelogoties',
      'All rights reserved': 'Visas tiesības aizsargātas',
      'Copyright': 'Autortiesības',
      'Logged': 'Logged',
      'Comments': 'Comments',
      'Whs': 'Whs',
      'Owner': 'Owner',
      'FirmId': 'FirmId',
      'POSID': 'POSID',
      'Pr Code': 'Pr Code',
      'Product': 'Product',
      'Supp': 'Supp',
      'Reason': 'Reason',
      'Fault': 'Fault',
      'Client': 'Client',
      'Deliv Date': 'Deliv Date',
      'Picked': 'Picked',
      'Ordered': 'Ordered',
      'Diff Qty': 'Diff Qty',
      'Pck list': 'Pck list',
      'Approved': 'Approved',
      'Client Address': 'Client Address',
      'Public Canceled Lines': 'Public Canceled Lines',
      'Comment': 'Comment',
      'Order No': 'Order No',
      'Document No': 'Document No',
      'Manager': 'Manager',
      'Activity': 'Activity',
      'EAN': 'EAN',
      'Diff %': 'Diff %',
      'Agent': 'Agent',
      'Tvnt': 'Tvnt',
      'Agent Email': 'Agent Email',
      'Supp Pr Code': 'Supp Pr Code',
      'Logged at': 'Logged at',
      'Yes': 'Yes',
      'No': 'No',
      'Report': 'Report',
      'Public Problems': 'Public Problems',
      'Public Problems list': 'Public Problems list',
      'Countries': 'Valstis',
      'Serial No': 'Sērijas numurs',
      'Created At': 'Izveidots',
      'Customer': 'Klients',
      'Address': 'Adrese',
      'Product code': 'Kods',
      'Product Name': 'Product Name',
      'Quantity': 'Quantity',
      'Problem': 'Problēma',
      'Decision': 'Decision',
      'To Department': 'Nodaļai',
      'Sale Group': 'Pārdošanas grupa',
      'Source': 'Avots',
      'Is confirmed': 'Is confirmed'
    }),
    // EE
    new MLanguage('4').create({
      'Version': 'Versioon',
      'Sign In': 'Logi sisse',
      'All rights reserved': 'Kõik õigused kaitstud',
      'Copyright': 'Autoriõigus',
      'Logged': 'Logged',
      'Comments': 'Comments',
      'Whs': 'Whs',
      'Owner': 'Owner',
      'FirmId': 'FirmId',
      'POSID': 'POSID',
      'Pr Code': 'Pr Code',
      'Product': 'Product',
      'Supp': 'Supp',
      'Reason': 'Reason',
      'Fault': 'Fault',
      'Client': 'Client',
      'Deliv Date': 'Deliv Date',
      'Picked': 'Picked',
      'Ordered': 'Ordered',
      'Diff Qty': 'Diff Qty',
      'Pck list': 'Pck list',
      'Approved': 'Approved',
      'Client Address': 'Client Address',
      'Public Canceled Lines': 'Public Canceled Lines',
      'Comment': 'Comment',
      'Order No': 'Order No',
      'Document No': 'Document No',
      'Manager': 'Manager',
      'Activity': 'Activity',
      'EAN': 'EAN',
      'Diff %': 'Diff %',
      'Agent': 'Agent',
      'Tvnt': 'Tvnt',
      'Agent Email': 'Agent Email',
      'Supp Pr Code': 'Supp Pr Code',
      'Logged at': 'Logged at',
      'Yes': 'Yes',
      'No': 'No',
      'Report': 'Report',
      'Public Problems': 'Public Problems',
      'Public Problems list': 'Public Problems list',
      'Countries': 'Countries',
      'Serial No': 'Serial No',
      'Created At': 'Created At',
      'Customer': 'Customer',
      'Address': 'Address',
      'Product code': 'Code',
      'Product Name': 'Product Name',
      'Quantity': 'Quantity',
      'Problem': 'Problem',
      'Decision': 'Decision',
      'To Department': 'To Department',
      'Sale Group': 'Sale Group',
      'Source': 'Source',
      'Is confirmed': 'Is confirmed'
    }),
    // RU
    new MLanguage('5').create({
      'Version': 'Версия',
      'Sign In': 'Войти в систему',
      'All rights reserved': 'Все права защищены',
      'Copyright': 'Авторские права',
      'Logged': 'Logged',
      'Comments': 'Comments',
      'Whs': 'Whs',
      'Owner': 'Owner',
      'FirmId': 'FirmId',
      'POSID': 'POSID',
      'Pr Code': 'Pr Code',
      'Product': 'Product',
      'Supp': 'Supp',
      'Reason': 'Reason',
      'Fault': 'Fault',
      'Client': 'Client',
      'Deliv Date': 'Deliv Date',
      'Picked': 'Picked',
      'Ordered': 'Ordered',
      'Diff Qty': 'Diff Qty',
      'Pck list': 'Pck list',
      'Approved': 'Approved',
      'Client Address': 'Client Address',
      'Public Canceled Lines': 'Public Canceled Lines',
      'Comment': 'Comment',
      'Order No': 'Order No',
      'Document No': 'Document No',
      'Manager': 'Manager',
      'Activity': 'Activity',
      'EAN': 'EAN',
      'Diff %': 'Diff %',
      'Agent': 'Agent',
      'Tvnt': 'Tvnt',
      'Agent Email': 'Agent Email',
      'Supp Pr Code': 'Supp Pr Code',
      'Logged at': 'Logged at',
      'Yes': 'Да',
      'No': 'Нет',
      'Report': 'Report',
      'Public Problems': 'Public Problems',
      'Public Problems list': 'Public Problems list',
      'Countries': 'Страны',
      'Serial No': 'Серийный номер',
      'Created At': 'Создано в',
      'Customer': 'Клиент',
      'Address': 'Адрес',
      'Product code': 'Код',
      'Product Name': 'Продукт',
      'Quantity': 'Количество',
      'Problem': 'Проблема',
      'Decision': 'Decision',
      'To Department': 'В департамент(отдел)',
      'Sale Group': 'Группа продажи',
      'Source': 'Источник',
      'Is confirmed': 'Подтверждено'
    })
  ],
  middleware: (component, path) => {
    return path
  }
})
