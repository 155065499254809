export default {
  state: {
    roles: [],
    returnsTodayCount: [],
    delaysTodayCount: [],
    mismatchesTodayCount: [],
    problemsTodayCount: [],
    canceledLinesTodayCount: [],
    loggedCanceledLinesTodayCount: [],
    canceledLinesByLangTodayCount: [],
    loggedCanceledLinesByLangTodayCount: [],
    nonWrittenCreditInvoiceCount: [],
    nonWrittenCreditInvoiceDate: []
  },
  mutations: {
    fetchUserRoles (state, payload) {
      state.roles = payload
    },
    fetchReturnsTodayCount (state, payload) {
      state.returnsTodayCount = payload
    },
    fetchDelaysTodayCount (state, payload) {
      state.delaysTodayCount = payload
    },
    fetchMismatchesTodayCount (state, payload) {
      state.mismatchesTodayCount = payload
    },
    fetchProblemsTodayCount (state, payload) {
      state.problemsTodayCount = payload
    },
    fetchNonWrittenCreditInvoiceCount (state, payload) {
      state.nonWrittenCreditInvoiceCount = payload
    },
    fetchNonWrittenCreditInvoiceDate (state, payload) {
      state.nonWrittenCreditInvoiceDate = payload
    },
    fetchCanceledLinesTodayCount (state, payload) {
      state.canceledLinesTodayCount = payload
    },
    fetchLoggedCanceledLinesTodayCount (state, payload) {
      state.loggedCanceledLinesTodayCount = payload
    },
    fetchCanceledLinesByLangTodayCount (state, payload) {
      state.canceledLinesByLangTodayCount = payload
    },
    fetchLoggedCanceledLinesByLangTodayCount (state, payload) {
      state.loggedCanceledLinesByLangTodayCount = payload
    }
  },
  actions: {
    fetchGetUserRoles ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getUserRole().then(result => {
          commit('fetchUserRoles', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetReturnsTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getReturnsTodayCount().then(result => {
          commit('fetchReturnsTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetDelaysTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getDelaysTodayCount().then(result => {
          commit('fetchDelaysTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetMismatchesTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getMismatchesTodayCount().then(result => {
          commit('fetchMismatchesTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetProblemsTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getProblemsTodayCount().then(result => {
          commit('fetchProblemsTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetNonWrittenCreditInvoiceCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getNonWrittenCreditInvoiceCount().then(result => {
          commit('fetchNonWrittenCreditInvoiceCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetNonWrittenCreditInvoiceDate ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getNonWrittenCreditInvoiceDate().then(result => {
          commit('fetchNonWrittenCreditInvoiceDate', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetLoggedCanceledLinesTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getLoggedCanceledLinesTodayCount().then(result => {
          commit('fetchLoggedCanceledLinesTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetCanceledLinesByLangTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getCanceledLinesByLangTodayCount().then(result => {
          commit('fetchCanceledLinesByLangTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    },
    fetchGetLoggedCanceledLinesByLangTodayCount ({ commit }) {
      return new Promise((resolve, reject) => {
        resource.getLoggedCanceledLinesByLangTodayCount().then(result => {
          commit('fetchLoggedCanceledLinesByLangTodayCount', result.data)
          resolve()
        }, result => {
          reject(result.data)
        })
      })
    }
  },
  getters: {
    fetchGetUserRoles: state => state.roles,
    fetchGetReturnsTodayCount: state => state.returnsTodayCount,
    fetchGetDelaysTodayCount: state => state.delaysTodayCount,
    fetchGetMismatchesTodayCount: state => state.mismatchesTodayCount,
    fetchGetProblemsTodayCount: state => state.problemsTodayCount,
    fetchGetNonWrittenCreditInvoiceCount: state => state.nonWrittenCreditInvoiceCount,
    fetchGetNonWrittenCreditInvoiceDate: state => state.nonWrittenCreditInvoiceDate,
    fetchGetCanceledLinesTodayCount: state => state.canceledLinesTodayCount,
    fetchGetLoggedCanceledLinesTodayCount: state => state.loggedCanceledLinesTodayCount,
    fetchGetCanceledLinesByLangTodayCount: state => state.canceledLinesByLangTodayCount,
    fetchGetLoggedCanceledLinesByLangTodayCount: state => state.loggedCanceledLinesByLangTodayCount
  }
}
