export default {
  methods: {
    tableCss () {
      return {
        tableClass: 'table table-hover table-condensed table-responsive',
        loadingClass: 'loading',
        ascendingIcon: 'glyphicon glyphicon-chevron-up',
        descendingIcon: 'glyphicon glyphicon-chevron-down',
        handleIcon: 'glyphicon glyphicon-menu-hamburger'
      }
    },
    paginationTopCss () {
      return {
        infoClass: 'pull-left',
        wrapperClass: 'vuetable-pagination pull-right',
        activeClass: 'btn-primary',
        disabledClass: 'disabled',
        pageClass: 'btn btn-border',
        linkClass: 'btn btn-border',
        icons: {
          first: '',
          prev: '',
          next: '',
          last: ''
        }
      }
    },
    loaderColor () {
      return '#3c8dbc'
    }
  }
}
