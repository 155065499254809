import Vue from 'vue'
import Vuex from 'vuex'
import dashboard from './components/store/dashboard'
import classifiers from './components/store/classifiers'
import auth from './components/store/auth'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    dashboard,
    classifiers,
    auth
  }
})

export default store
