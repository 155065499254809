export default {
  // Events
  saveEvent: { method: 'POST', url: '/api/events/saveEvent' },
  updateEvent: { method: 'POST', url: '/api/events/updateEvent' },
  deleteEvent: { method: 'DELETE', url: '/api/events/deleteEvent' },
  loadEventProdByProdCode: { method: 'GET', url: '/api/EventsData/searchProductByProductCode' },
  loadEventProdByProdCodeInvoiceNo: { method: 'GET', url: '/api/EventsData/searchProductByProductCodeInvoiceNo' },
  getEventsOwnTypesList: { method: 'GET', url: '/api/classifiers/getEventsOwnTypesList' },
  viewEventLog: { method: 'GET', url: '/api/eventLogs{/eventType}{/eventId}' },
  generateEventsReport: { method: 'POST', url: '/api/events/generateReport' },
  addEventHistoryLogItem: { method: 'POST', url: '/api/eventLogs' },
  addMultipleCloseLogs: { method: 'POST', url: '/api/eventLogs/multiClose' },
  addMultipleApproveLogs: { method: 'POST', url: '/api/eventLogs/multiApprove' },
  addCanceledLineListLogs: { method: 'POST', url: '/api/eventLogs/multiCanceledLine' },
  changeMultipleReturnStatuses: { method: 'POST', url: '/api/eventLogs/returns/multipleStatusChange' },

  // Canceled Lines
  postCanceledLine: { method: 'POST', url: '/api/canceledLines/postCanceledLine' },
  postApprove: { method: 'POST', url: '/api/canceledLines/postApprove' },
  editCanceledLine: { method: 'POST', url: '/api/canceledLines/editCanceledLine' },
  deleteCanceledLineLog: { method: 'DELETE', url: '/api/canceledLines/deleteCanceledLineLog' },
  getCanceledLinesWhsTypesList: { method: 'GET', url: '/api/classifiers/getCanceledLinesWhsTypesList' },
  getCanceledLinesWhsTypesListByFirmId: { method: 'GET', url: '/api/classifiers/getCanceledLinesWhsTypesListByFirmId' },
  getCanceledLinesOwnTypesList: { method: 'GET', url: '/api/classifiers/getCanceledLinesOwnTypesList' },
  getCanceledLinesFirmIdTypesList: { method: 'GET', url: '/api/classifiers/getCanceledLinesFirmIdTypesList' },
  generateCanceledLinesReport: { method: 'POST', url: '/api/canceledLines/generateReport' },
  generatePublicCanceledLinesReport: { method: 'POST', url: '/api/canceledLines/generatePublicReport' },
  getCanceledLineById: { method: 'GET', url: '/api/canceledLines/getCanceledLineById' },
  getApprovedLinesCountData: { method: 'GET', url: '/api/canceledLines/approvedLinesCountData' },
  loadStockByWarehouse: { method: 'GET', url: '/api/canceledLines/loadStockByWarehouse{/whs}' },
  getPublicCanceledLineById: { method: 'GET', url: '/api/canceledLines/public{/id}' },

  // Classifiers
  getEventTypes: { method: 'GET', url: '/api/classifiers/getEventTypes' },
  postEventType: { method: 'POST', url: '/api/classifiers/postEventType' },
  deleteEventType: { method: 'DELETE', url: '/api/classifiers/deleteEventType' },

  getEventDelayTypes: { method: 'GET', url: '/api/classifiers/getEventDelayTypes' },
  postEventDelayType: { method: 'POST', url: '/api/classifiers/postEventDelayType' },
  deleteEventDelayType: { method: 'DELETE', url: '/api/classifiers/deleteEventDelayType' },

  getEventNonconformityTypes: { method: 'GET', url: '/api/classifiers/getEventNonconformityTypes' },
  postEventNonconformityType: { method: 'POST', url: '/api/classifiers/postEventNonconformityType' },
  deleteEventNonconformityType: { method: 'DELETE', url: '/api/classifiers/deleteEventNonconformityType' },
  getNonconformityTypesByUserGroup: { method: 'GET', url: '/api/classifiers/nonconformityTypesByUserGroup' },

  getEventNonconformityReasonTypesByNonconformityId: { method: 'GET', url: '/api/classifiers/getEventNonconformityReasonTypesByNonconformityId' },
  getEventNonconformityReasonTypes: { method: 'GET', url: '/api/classifiers/getEventNonconformityReasonTypes' },
  postEventNonconformityReasonType: { method: 'POST', url: '/api/classifiers/postEventNonconformityReasonType' },
  deleteEventNonconformityReasonType: { method: 'DELETE', url: '/api/classifiers/deleteEventNonconformityReasonType' },
  getPickUpReasonTypes: { method: 'GET', url: '/api/classifiers/pickupReasonTypes' },

  getEventFaultTypes: { method: 'GET', url: '/api/classifiers/getEventFaultTypes' },
  postEventFaultType: { method: 'POST', url: '/api/classifiers/postEventFaultType' },
  deleteEventFaultType: { method: 'DELETE', url: '/api/classifiers/deleteEventFaultType' },

  getStatusTypes: { method: 'GET', url: '/api/classifiers/getStatusTypes' },
  postStatusType: { method: 'POST', url: '/api/classifiers/postStatusType' },
  deleteStatusType: { method: 'DELETE', url: '/api/classifiers/deleteStatusType' },

  getRowStatusTypes: { method: 'GET', url: '/api/classifiers/getRowStatusTypes' },
  postRowStatusType: { method: 'POST', url: '/api/classifiers/postRowStatusType' },
  deleteRowStatusType: { method: 'DELETE', url: '/api/classifiers/deleteRowStatusType' },

  getReturnTypes: { method: 'GET', url: '/api/classifiers/getReturnTypes' },
  postReturnType: { method: 'POST', url: '/api/classifiers/postReturnType' },
  deleteReturnType: { method: 'DELETE', url: '/api/classifiers/deleteReturnType' },

  getWarehouseStateTypes: { method: 'GET', url: '/api/classifiers/getWarehouseStateTypes' },
  postWarehouseStateType: { method: 'POST', url: '/api/classifiers/postWarehouseStateType' },
  deleteWarehouseStateType: { method: 'DELETE', url: '/api/classifiers/deleteWarehouseStateType' },

  getCanceledLineReasonTypes: { method: 'GET', url: '/api/classifiers/getCanceledLineReasonTypes' },
  postCanceledLineReasonType: { method: 'POST', url: '/api/classifiers/postCanceledLineReasonType' },
  deleteCanceledLineReasonType: { method: 'DELETE', url: '/api/classifiers/deleteCanceledLineReasonType' },

  getCanceledLineFaultTypes: { method: 'GET', url: '/api/classifiers/getCanceledLineFaultTypes' },
  postCanceledLineFaultType: { method: 'POST', url: '/api/classifiers/postCanceledLineFaultType' },
  deleteCanceledLineFaultType: { method: 'DELETE', url: '/api/classifiers/deleteCanceledLineFaultType' },

  getCanceledLineCommentTypes: { method: 'GET', url: '/api/classifiers/getCanceledLineCommentTypes' },
  postCanceledLineCommentType: { method: 'POST', url: '/api/classifiers/postCanceledLineCommentType' },
  deleteCanceledLineCommentType: { method: 'DELETE', url: '/api/classifiers/deleteCanceledLineCommentType' },

  getCanceledLineApproveTypes: { method: 'GET', url: '/api/classifiers/getCanceledLineApproveTypes' },
  postCanceledLineApproveType: { method: 'POST', url: '/api/classifiers/postCanceledLineApproveType' },
  deleteCanceledLineApproveType: { method: 'DELETE', url: '/api/classifiers/deleteCanceledLineApproveType' },

  getCanceledLineCommentTypesByReason: { method: 'GET', url: '/api/classifiers/getCanceledLineCommentTypesByReason' },

  getCarrierTypes: { method: 'GET', url: '/api/classifiers/getCarrierTypes' },
  postCarrierType: { method: 'POST', url: '/api/classifiers/postCarrierType' },
  deleteCarrierType: { method: 'DELETE', url: '/api/classifiers/deleteCarrierType' },

  getLanguageTypes: { method: 'GET', url: '/api/classifiers/getLanguageTypes' },
  postLanguageType: { method: 'POST', url: '/api/classifiers/postLanguageType' },
  deleteLanguageType: { method: 'DELETE', url: '/api/classifiers/deleteLanguageType' },

  getInformedPersonTypes: { method: 'GET', url: '/api/classifiers/getInformedPersonTypes' },
  postInformedPersonType: { method: 'POST', url: '/api/classifiers/postInformedPersonType' },
  deleteInformedPersonType: { method: 'DELETE', url: '/api/classifiers/deleteInformedPersonType' },

  getCategoryTypes: { method: 'GET', url: '/api/classifiers/getCategoryTypes' },
  postCategoryType: { method: 'POST', url: '/api/classifiers/postCategoryType' },
  deleteCategoryType: { method: 'DELETE', url: '/api/classifiers/deleteCategoryType' },

  getCountryTypes: { method: 'GET', url: '/api/classifiers/getCountryTypes' },
  postCountryType: { method: 'POST', url: '/api/classifiers/postCountryType' },
  deleteCountryType: { method: 'DELETE', url: '/api/classifiers/deleteCountryType' },
  getAccessibleCountryTypes: { method: 'GET', url: '/api/classifiers/countryTypes/accessible' },

  getClsOwners: { method: 'GET', url: '/api/classifiers/getClsOwners' },
  postClsOwner: { method: 'POST', url: '/api/classifiers/postClsOwner' },
  deleteClsOwner: { method: 'DELETE', url: '/api/classifiers/deleteClsOwner' },

  getPriorityTypes: { method: 'GET', url: '/api/classifiers/getPriorityTypes' },
  postPriorityType: { method: 'POST', url: '/api/classifiers/postPriorityType' },
  deletePriorityType: { method: 'DELETE', url: '/api/classifiers/deletePriorityType' },

  getFlagsTypes: { method: 'GET', url: '/api/classifiers/getFlagsTypes' },
  postFlagType: { method: 'POST', url: '/api/classifiers/postFlagType' },
  deleteFlagType: { method: 'DELETE', url: '/api/classifiers/deleteFlagType' },

  getResponsibilityTypes: { method: 'GET', url: '/api/classifiers/getResponsibilityTypes' },
  postResponsibilityType: { method: 'POST', url: '/api/classifiers/postResponsibilityType' },
  deleteResponsibilityType: { method: 'DELETE', url: '/api/classifiers/deleteResponsibilityType' },

  getDecisionTypes: { method: 'GET', url: '/api/classifiers/getDecisionTypes' },
  getDecisionTypeExtraField: { method: 'GET', url: '/api/classifiers/getDecisionTypeExtraField' },
  getDecisionTypeMandatoryAttachment: { method: 'GET', url: '/api/classifiers/getDecisionTypeMandatoryAttachment' },
  postDecisionType: { method: 'POST', url: '/api/classifiers/postDecisionType' },
  deleteDecisionType: { method: 'DELETE', url: '/api/classifiers/deleteDecisionType' },

  postSerialNumber: { method: 'POST', url: '/api/appSerialNumbers/postSerialNumber' },
  deleteSerialNumber: { method: 'DELETE', url: '/api/appSerialNumbers/deleteSerialNumber' },

  getDepartmentTypes: { method: 'GET', url: '/api/classifiers/getDepartmentTypes' },
  getActiveDepartmentTypes: { method: 'GET', url: '/api/classifiers/getActiveDepartmentTypes' },
  getDefaultDepartmentTypes: { method: 'GET', url: 'api/classifiers/getDefaultDepartmentTypes' },
  getDepartmentTypesByUserLanguage: { method: 'GET', url: 'api/classifiers/getDepartmentTypesByUserLanguage' },
  getDepartmentTypesByCountries: { method: 'GET', url: 'api/classifiers/getDepartmentTypesByCountries' },
  getPersonsByDepartmentType: { method: 'GET', url: '/api/classifiers/getPersonsByDepartmentType' },
  getAndSetPersonByDepartmentType: { method: 'GET', url: '/api/classifiers/getAndSetPersonByDepartmentType' },

  getUserAccessableWhsList: { method: 'GET', url: '/api/classifiers/userAccessableWhsList' },
  getDriverByUserId: { method: 'GET', url: '/api/clsDriversData/getDriverByUserId' },

  getWarehousesByCountry: { method: 'GET', url: '/api/classifiers/warehouses{/countryTypeId}' },
  getClsStockDesc: { method: 'GET', url: '/api/classifiers/getClsStockDesc' },

  // Common
  getAppSettings: { method: 'GET', url: '/api/appSettings/getAppSettings' },
  postAppSetting: { method: 'POST', url: '/api/appSettings/postAppSetting' },
  deleteAppSetting: { method: 'DELETE', url: '/api/appSettings/deleteAppSetting' },

  // Users
  getUser: { method: 'GET', url: '/api/users/active' },
  getAllUsers: { method: 'GET', url: '/api/users/getAllUsers' },
  getUserRole: { method: 'GET', url: 'getUserRole' },
  saveUser: { method: 'POST', url: '/api/users/saveUser' },
  changePassword: { method: 'POST', url: '/api/users/changePassword' },
  postUser: { method: 'POST', url: '/api/users' },
  getProfileImage: { method: 'GET', url: '/api/users/getProfileImage' },
  addFollowing: { method: 'POST', url: '/api/users/addFollowing' },
  removeFollowing: { method: 'POST', url: '/api/users/removeFollowing' },
  getUserInfo: { method: 'GET', url: '/api/users/getUserInfo{/id}' },
  getFullNameAndIdPairList: { method: 'GET', url: '/api/users/fullNameAndIdPairList' },
  getDepartmentUsers: { method: 'GET', url: '/api/users/byDepartment{/id}' },
  getPosidsByGroup: { method: 'GET', url: '/api/users/customerRelatedPosids{/userGroupId}' },

  // Users Group
  getClsOwnersByCountry: { method: 'GET', url: '/api/appUserGroups/getClsOwners' },
  postUserGroup: { method: 'POST', url: '/api/appUserGroups/postUserGroup' },
  deleteUserGroup: { method: 'DELETE', url: '/api/appUserGroups/deleteUserGroup' },
  getUserGroupsKeyToValue: { method: 'GET', url: '/api/appUserGroups' },
  getUserGroupsByCountryId: { method: 'GET', url: '/api/appUserGroups/FilterByCountry{/countryId}' },
  getCustomerListByOwners: { method: 'POST', url: '/api/appUserGroups/ownerListRelatedCustomers' },
  getDataLimitAllowedDates: { method: 'GET', url: '/api/appUserGroups/dataLimitAllowedDates' },

  // ClsDepartments
  getDepartment: { method: 'GET', url: '/api/clsDepartments/getDepartment{/id}' },
  getDepartments: { method: 'GET', url: '/api/clsDepartments/getDepartments' },
  postDepartment: { method: 'POST', url: '/api/clsDepartments/postDepartment' },
  deleteDepartment: { method: 'DELETE', url: '/api/clsDepartments/deleteDepartment{/id}' },
  putDepartment: { method: 'PUT', url: '/api/clsDepartments/putDepartment' },
  getDepartmentsByUserGroupId: { method: 'GET', url: '/api/clsDepartments/FilteredByUserGroup{/userGroupId}' },

  // Dashboard
  getReturnsTodayCount: { method: 'GET', url: '/api/dashboard/returnsTodayCount' },
  getDelaysTodayCount: { method: 'GET', url: '/api/dashboard/delaysTodayCount' },
  getMismatchesTodayCount: { method: 'GET', url: '/api/dashboard/mismatchesTodayCount' },
  getProblemsTodayCount: { method: 'GET', url: '/api/dashboard/problemsTodayCount' },
  getCanceledLinesTodayCount: { method: 'GET', url: '/api/dashboard/canceledLinesTodayCount' },
  getLoggedCanceledLinesTodayCount: { method: 'GET', url: '/api/dashboard/loggedCanceledLinesTodayCount' },
  getCanceledLinesByLangTodayCount: { method: 'GET', url: '/api/dashboard/canceledLinesByLangTodayCount' },
  getLoggedCanceledLinesByLangTodayCount: { method: 'GET', url: '/api/dashboard/loggedCanceledLinesByLangTodayCount' },
  getNonWrittenCreditInvoiceCount: { method: 'GET', url: '/api/dashboard/nonWrittenCreditInvoiceCount' },
  getNonWrittenCreditInvoiceDate: { method: 'GET', url: '/api/dashboard/nonWrittenCreditInvoiceDate' },

  // AppTranslations
  importTranslations: { method: 'GET', url: '/api/appTranslations/importTranslations' },
  exportTranslations: { method: 'GET', url: '/api/appTranslations/exportTranslations' },
  streamExportTranslations: { method: 'GET', url: '/api/appTranslations/streamExportTranslations' },
  translationsCountByLanguage: { method: 'GET', url: '/api/appTranslations/translationsCountByLanguage' },
  saveTranslation: { method: 'POST', url: '/api/appTranslations/saveTranslation' },
  getTranslationsDictionaryByLanguage: { method: 'GET', url: '/api/appTranslations/getTranslationsDictionaryByLanguage' },

  // AppFiles
  deleteAppFile: { method: 'DELETE', url: '/api/appFiles/deleteAppFile' },
  exportFile: { method: 'GET', url: '/api/appFiles/exportFile' },
  editFile: { method: 'POST', url: '/api/appFiles/editFile' },
  getFiles: { method: 'GET', url: '/api/appFiles/getFiles' },

  // AppComments
  saveComment: { method: 'POST', url: '/api/appComments/postAppComments' },
  getComments: { method: 'GET', url: '/api/appComments/getComments' },
  updateComment: { method: 'POST', url: '/api/appComments/updateComment' },
  getAllUsersByCarrier: { method: 'POST', url: '/api/users/getAllUsersByCarrier' },
  getAllInternalUsersByCarrier: { method: 'POST', url: '/api/users/getAllInternalUsersByCarrier' },
  getTopUsersByCarrier: { method: 'POST', url: '/api/users/getTopUsersByCarrier' },
  getTopInternalUsersByCarrier: { method: 'POST', url: '/api/users/getTopInternalUsersByCarrier' },
  getComment: { method: 'GET', url: '/api/appComments/getComment{/id}' },
  getUserRelatedComments: { method: 'GET', url: '/api/appComments/getUserRelatedComments' },
  getUnreadCommentNotifications: { method: 'GET', url: '/api/appComments/notifications' },
  setCommentsAsSeen: { method: 'POST', url: '/api/appComments/setCommentsAsSeen' },
  getProblemsUnreadCommentNotifications: { method: 'GET', url: '/api/appComments/getProblemsUnreadCommentNotifications' },
  hasUnreadInternalComments: { method: 'GET', url: '/api/appComments/hasUnreadInternalComments' },

  // AppAssigneeRules
  deleteAssigneeRule: { method: 'DELETE', url: '/api/assigneeRules{/id}' },
  saveAssigneeRule: { method: 'POST', url: '/api/assigneeRules' },
  getAssigneeRule: { method: 'GET', url: '/api/assigneeRules{/id}' },

  // clsStock
  getWarehousesKeyToValue: { method: 'GET', url: '/api/clsStockData/getWarehousesKeyToValue' },
  getPrefStocksKeyToValue: { method: 'GET', url: '/api/clsStockDescData/getPrefStocksKeyToValue' },

  // clsSaleGrp
  getClsSaleGrp: { method: 'GET', url: '/api/classifiers/getClsSaleGrp' },
  getSaleGroupsKeyToValue: { method: 'GET', url: '/api/clsSaleGrpData/getSaleGroupsKeyToValue' },

  // clsBrances
  getClsBranches: { method: 'GET', url: '/api/classifiers/getClsBranches' },

  // UserRole
  getRole: { method: 'GET', url: '/api/userRoles{/id}' },
  updateUserRole: { method: 'PUT', url: '/api/userRoles{/id}' },
  postUsersRole: { method: 'POST', url: '/api/userRoles' },
  getUsersRoles: { method: 'GET', url: '/api/userRoles' },
  deleteUsersRole: { method: 'DELETE', url: '/api/userRoles{/id}' },
  getUserRolesByUserType: { method: 'GET', url: '/api/userRoles/filterByUserType{/userType}' },

  // AccessRule
  getAccessRules: { method: 'GET', url: '/api/appAccessRules' },
  getPathAccessRules: { method: 'GET', url: '/api/appAccessRules/path{/pathCode}' },
  getAllPathAccessRules: { method: 'GET', url: '/api/appAccessRules/allPath' },

  // clsOwners
  getOwnersByUserGroupId: { method: 'GET', url: '/api/clsOwners/FilterByUserGroup{/userGroupId}' },
  getAllOwners: { method: 'GET', url: '/api/clsOwners' },

  // Problems
  getProblemById: { method: 'GET', url: '/api/problems/getProblemById' },
  getRelatedProblemsByPosId: { method: 'GET', url: '/api/problems/getRelatedProblemsByPosId' },
  postProblem: { method: 'POST', url: '/api/problems/postProblem' },
  updateProblem: { method: 'POST', url: '/api/problems/UpdateProblem' },
  updateProblemAgreement: { method: 'POST', url: '/api/problems/UpdateProblemAgreement' },
  disagreeWithAssignmentByRow: { method: 'POST', url: '/api/problems/disagreeWithAssignmentByRow' },
  notCountableByRow: { method: 'POST', url: '/api/problems/notCountableByRow' },
  getSerialsNo: { method: 'GET', url: '/api/problems/GetAllSerialsNo' },
  getPosids: { method: 'GET', url: '/api/problems/GetAllPosids' },
  deleteCountryFromProblem: { method: 'POST', url: '/api/problems/deleteCountryFromProblem' },
  addCountryToProblem: { method: 'POST', url: '/api/problems/addCountryToProblem' },
  setFlagToProblem: { method: 'POST', url: '/api/problems/setFlagToProblem' },
  viewProblemLog: { method: 'GET', url: '/api/problemsLog/viewProblemLog' },
  generateProblemsReport: { method: 'POST', url: '/api/problems/generateReport' },
  getFilesCount: { method: 'GET', url: '/api/problems/getFilesCount' },
  deleteProduct: { method: 'DELETE', url: '/api/problems/deleteProduct' },
  deleteUnknownOrderRow: { method: 'DELETE', url: '/api/problems/deleteUnknownOrderRow' },
  getProblemsOwnTypesList: { method: 'GET', url: '/api/classifiers/getProblemsOwnTypesList' },
  getProblemsBranchOfficeTypesList: { method: 'GET', url: '/api/classifiers/getProblemsBranchOfficeTypesList' },
  getDelaysBranchOfficeTypesList: { method: 'GET', url: '/api/classifiers/getDelaysBranchOfficeTypesList' },
  closeSelectedProblems: { method: 'POST', url: '/api/problems/closeSelectedProblems' },
  generateProblemsPublicReport: { method: 'POST', url: '/api/problemsPublic/report' },
  getProblemDriverSignature: { method: 'GET', url: '/api/problems/getProblemDriverSignature' },
  generateAct: { method: 'GET', url: '/api/problems{/id}/act' },

  // Flag
  setUserEventFlag: { method: 'PUT', url: '/api/clsFlags/userEventFlag' },
  clearUserEventFlag: { method: 'DELETE', url: '/api/clsFlags/userEventFlag' },

  // Delay
  getRelatedDelays: { method: 'GET', url: '/api/delays{/posid}/related' },
  saveDelay: { method: 'POST', url: '/api/delays' },
  getDelayById: { method: 'GET', url: '/api/delays{/id}' },
  updateDelay: { method: 'PUT', url: '/api/delays{/id}' },
  addCountryToDelay: { method: 'POST', url: '/api/delays{/id}/country{/countryId}' },
  deleteCountryFromDelay: { method: 'DELETE', url: '/api/delays{/id}/country{/countryId}' },
  generateDelaysReport: { method: 'POST', url: '/api/delays/report' },
  updateStatus: { method: 'PUT', url: '/api/delays{/id}/status' },
  getDelaySerials: { method: 'GET', url: '/api/delays/serials' },
  getDelayPosids: { method: 'GET', url: '/api/delays/posids' },
  closeSelectedDelays: { method: 'POST', url: '/api/delays/closeSelectedDelays' },
  getPublicDelayById: { method: 'GET', url: '/api/delaysPublic/public{/id}' },

  // Reports
  generateMistakesReport: { method: 'POST', url: '/api/mistakesReport/generateReport' },
  generateProblemProductsReport: { method: 'POST', url: '/api/problemProductsReport/report' },
  generateProblemByDepartmentsReport: { method: 'POST', url: '/api/problemByDepartmentsReport/report' },

  // notifications by owner/customer
  deleteNotificationByOwnerCustomer: { method: 'DELETE', url: '/api/notificationsByOwnerCustomer{/id}' },
  updateNotificationByOwnerCustomer: { method: 'PUT', url: '/api/notificationsByOwnerCustomer{/id}' },
  saveNotificationByOwnerCustomer: { method: 'POST', url: '/api/notificationsByOwnerCustomer' },
  getNotificationByOwnerCustomerById: { method: 'GET', url: '/api/notificationsByOwnerCustomer{/id}' },

  // notifications by priority
  deleteNotificationByPriority: { method: 'DELETE', url: '/api/notificationsByPriority{/id}' },
  updateNotificationByPriority: { method: 'PUT', url: '/api/notificationsByPriority{/id}' },
  saveNotificationByPriority: { method: 'POST', url: '/api/notificationsByPriority' },
  getNotificationByPriorityById: { method: 'GET', url: '/api/notificationsByPriority{/id}' },
  checkForNotificationCombination: { methopd: 'GET', url: '/api/notificationsByPriority/combinations' },

  // Returns
  postReturn: { method: 'POST', url: '/api/returns/postReturn' },
  getReturnById: { method: 'GET', url: '/api/returns/getReturnById' },
  closeSelectedReturns: { method: 'POST', url: '/api/returns/closeSelectedReturns' },
  deleteCountryFromReturn: { method: 'POST', url: '/api/returns/deleteCountry' },
  addCountryToReturn: { method: 'POST', url: '/api/returns/addCountry' },
  deleteReturnProduct: { method: 'DELETE', url: '/api/returns/deleteProduct' },
  updateReturn: { method: 'POST', url: '/api/returns/updateReturn' },
  getReturnsSerialsNo: { method: 'GET', url: '/api/returns/GetAllSerialsNo' },
  getReturnsPosids: { method: 'GET', url: '/api/returns/GetAllPosids' },
  deleteReturnsResponsibility: { method: 'DELETE', url: '/api/returns/deleteResponsibility' },
  updateMultipleReturnStatuses: { method: 'PUT', url: '/api/returns/multipleReturnStatuses' },
  notCountableReturnProducts: { method: 'POST', url: '/api/returns/notCountableProducts' },
  getPublicReturnById: { method: 'GET', url: '/api/returnsPublic{/id}' },
  registerToWarehouse: { method: 'POST', url: '/api/returnsWarehouse{/id}/registration' },

  // activities data
  getActivityData: { method: 'GET', url: '/api/activityData/activities' },
  getAllActivities: { method: 'GET', url: '/api/activityData/activities/all' },

  // day offs
  postAppDayOff: { method: 'POST', url: '/api/appdayoffs' },
  updateAppDayOff: { method: 'PUT', url: '/api/appdayoffs{/id}' },
  deleteAppDayOff: { method: 'DELETE', url: '/api/appdayoffs{/id}' },

  // price ranges
  postAppProductPriceRange: { method: 'POST', url: '/api/appProductPriceRanges' },
  updateAppProductPriceRange: { method: 'PUT', url: '/api/appProductPriceRanges{/id}' },
  deleteAppProductPriceRange: { method: 'DELETE', url: '/api/appProductPriceRanges{/id}' },
  getProductPriceRanges: { method: 'GET', url: '/api/appProductPriceRanges' },

  // assignee position codes
  getAssigneePositionCodes: { method: 'GET', url: '/api/assigneePositionCodes' },
  getDepartmentId: { method: 'GET', url: '/api/assigneeRules/department' },

  // return confirmation
  postRequestToConfirm: { method: 'POST', url: '/api/returns{/id}/confirmationRequest' },
  postConfirmation: { method: 'POST', url: '/api/returns{/id}/confirmation' },

  postAppReturnWhsAssignment: { method: 'POST', url: '/api/appReturnWhsAssignments' },
  updateAppReturnWhsAssignment: { method: 'PUT', url: '/api/appReturnWhsAssignments{/id}' },

  // quality assessment type clasifier
  postQualityAssessmentType: { method: 'POST', url: '/api/clsQualityAssessmentTypes' },
  updateQualityAssessmentType: { method: 'PUT', url: '/api/clsQualityAssessmentTypes{/id}' },
  deleteQualityAssessmentType: { method: 'DELETE', url: '/api/clsQualityAssessmentTypes{/id}' },
  getQualityAssessmentTypes: { method: 'GET', url: '/api/clsQualityAssessmentTypes' },

  //inbound act reason type clasifier
  postInboundActReasonType: { method: 'POST', url: '/api/clsInboundActReasonTypes' },
  updateInboundActReasonType: { method: 'PUT', url: '/api/clsInboundActReasonTypes{/id}' },
  deleteInboundActReasonType: { method: 'DELETE', url: '/api/clsInboundActReasonTypes{/id}' },
  getInboundActReasonTypes: { method: 'GET', url: '/api/clsInboundActReasonTypes' },

  // Inbound Acts
  postInboundAct: { method: 'POST', url: '/api/inboundActs' },
  updateInboundAct: { method: 'PUT', url: '/api/inboundActs{/id}' },
  getInboundActById: { method: 'GET', url: '/api/inboundActs{/id}' },

  //inbound act product state
  postProductState: { method: 'POST', url: '/api/clsProductStates' },
  updateProductState: { method: 'PUT', url: '/api/clsProductStates{/id}' },
  deleteProductState: { method: 'DELETE', url: '/api/clsProductStates{/id}' },
  getProductStates: { method: 'GET', url: '/api/clsProductStates' },

  //inbound act carrier
  postInboundActCarrier: { method: 'POST', url: '/api/clsInboundActCarriers' },
  updateInboundActCarrier: { method: 'PUT', url: '/api/clsInboundActCarriers{/id}' },
  deleteInboundActCarrier: { method: 'DELETE', url: '/api/clsInboundActCarriers{/id}' },
  getInboundActCarriers: { method: 'GET', url: '/api/clsInboundActCarriers' },
  
  // inbound act suppliers
  getSuppliers: { method: 'GET', url: '/api/inboundActs/suppliers' },

  // claims
  postClaims: { method: 'POST', url: '/api/claims/saveClaims' }
}
