export default {
  methods: {
    showByRole (rolesToAllow, userRole) {
      for (var i = 0; i < rolesToAllow.length; i++) {
        if (rolesToAllow[i].id == userRole) {
          return true
        }
      }
      return false
    },
    hideByRole (rolesToHide, userRole) {
      for (var i = 0; i < rolesToHide.length; i++) {
        if (rolesToHide[i].id == userRole) {
          return false
        }
      }
      return true
    }
  }
}
