<template>
  <select v-model="data" select="" class="input-sm">
    <option v-for="option in options" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    options: {
      type: Array,
      default: () => ([5, 15, 30, 50, 100])
    }
  },
  data () {
    return {
      data: this.value
    }
  },
  watch: {
    data (value) {
      this.$emit('input', value)
      this.$nextTick(() => {
        this.$emit('change')
      })
    }
  }
}
// EXAMPLE
// <v-show-entries v-model="perPage" @change="updateTable"></v-show-entries>
</script>
