import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import VueResource from 'vue-resource'

Vue.use(Router)
Vue.use(VueResource)

let routes = [
  {
    path: '/',
    component: () => import('./views/Dashboard/dashboard.vue'),
    name: 'Dashboard',
    meta: {
      showBackBtn: false, public: false, breadcrumb: 'Dashboard' }
  },
  {
    path: '/Users/Settings',
    component: () => import('./views/Users/userSettings.vue'),
    name: 'User Settings',
    meta: { showBackBtn: false, public: false, breadcrumb: 'User Settings' }
  },
  {
    path: '/CanceledLines',
    component: () => import('./views/CanceledLines/canceledLinesList.vue'),
    name: 'Canceled Lines',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Canceled Lines' },
    children: [
      { path: '/CanceledLine/:id', component: () => import('./views/CanceledLines/canceledLineDetails.vue'), name: 'Canceled Line', meta: { showBackBtn: true, public: false, breadcrumb: 'Canceled Line' } }
    ]
  },
  {
    path: '/Problems',
    component: () => import('./views/Problems/problemsList.vue'),
    name: 'Problems',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Problems' },
    children: [
      { path: '/Problem', component: () => import('./views/Problems/problemAdd.vue'), name: 'Creating problem', meta: { showBackBtn: false, public: false, breadcrumb: 'Creating problem' } },
      { path: '/Problem/:id', component: () => import('./views/Problems/problemDetails.vue'), name: 'Problem', meta: { showBackBtn: false, public: false, breadcrumb: 'Problem' } }
    ]
  },
  {
    path: '/Delays',
    component: () => import('./views/Delays/delayList.vue'),
    name: 'Delays',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Delays' },
    children: [
      { path: '/Delay/:id', component: () => import('./views/Delays/delayDetails.vue'), name: 'Delay', meta: { showBackBtn: false, public: false, breadcrumb: 'Delay' } }
    ]
  },
  {
    path: '/Returns',
    component: () => import('./views/Returns/returnList.vue'),
    name: 'Returns',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Returns' },
    children: [
      { path: '/Return', component: () => import('./views/Returns/returnAdd.vue'), name: 'Creating return', meta: { showBackBtn: false, public: false, breadcrumb: 'Creating return' } },
      { path: '/Return/:id', component: () => import('./views/Returns/returnDetails.vue'), name: 'Return', meta: { showBackBtn: false, public: false, breadcrumb: 'Return' } }
    ]
  },
  {
    path: '/InboundActs',
    component: () => import('./views/InboundActs/inboundActList.vue'),
    name: 'Inbound Acts',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Inbound Acts' },
    children: [
      { path: '/InboundAct', component: () => import('./views/InboundActs/inboundActAdd.vue'), name: 'Creating Inbound Act', meta: { showBackBtn: false, public: false, breadcrumb: 'Creating Inbound Act' } },
      { path: '/InboundAct/:id', component: () => import('./views/InboundActs/inboundActDetails.vue'), name: 'Inbound Act', meta: { showBackBtn: false, public: false, breadcrumb: 'Inbound Act' } }
    ]
  },
  {
    path: '/Report/Problems',
    component: () => import('./views/Reports/Problems/index.vue'),
    name: 'Problem Products Report',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Problems' }
  },
  {
    path: '/Report/ProblemsByDepartments',
    component: () => import('./views/Reports/ProblemsByDepartments/problemsByDepartmentsList.vue'),
    name: 'Problems By Departments Report',
    meta: { showBackBtn: false, public: false, breadcrumb: 'Problems By Departments' }
  },
  {
    path: '/login',
    component: () => import('./views/Auth/login.vue'),
    name: 'Login',
    meta: { showBackBtn: false, public: true }
  },
  {
    path: '*',
    component: () => import('./views/Shared/404.vue'),
    meta: { showBackBtn: false, public: false, breadcrumb: 'Error' }
  },
  {
    path: '/Claims',
    component: () => import('./views/Claims/Index.vue'),
    name: 'Claims',
    meta: { showBackBtn: false, public: true, breadcrumb: 'Claims' }
  }
]

let router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: routes,
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.public)) {
    if (store.getters.isLoggedIn) {
      if (from.name === 'Login') {
        window.location.href = from.query.redirect || '/'
      }
      else {
        next()
      }
    }
    else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }
  else {
    next()
  }
})

export default router
