function headers (route, scroll) {
  let header = $('.fixed-header > thead > tr')
  if (header.length == 0) {
    return
  }
  let addHeaderOn = 0
  addHeaderOn = header.offset().top - header.height()
  if (header.length == 1) {
    header.clone().appendTo('.fixed-header > thead')
  }
  header = $('.fixed-header > thead > tr:nth-child(2)')
  header.css('display', 'none')
  header.css('z-index', '100')
  header.css('position', 'fixed')
  header.css('top', '0px')
  header.css('background-color', 'white')
  let trCount = $(`.fixed-header > tbody > tr`).length
  if (document.documentElement.scrollTop > addHeaderOn &&
        header.closest('.table-responsive').get(0).scrollWidth <= header.closest('.table-responsive').width() &&
        trCount > 1) {
    header.css('display', 'table-row')
    let columnCount = $('.fixed-header > tbody > tr:first-child > td').length
    for (let index = 1; index <= columnCount; index++) {
      let column = $(`.fixed-header > tbody > tr:nth-child(1) > td:nth-child(${index})`)
      $(`.fixed-header > thead > tr:nth-child(2) > th:nth-child(${index})`).css('width', column.css('width'))
    }

    var hideHeaderCheckBox = document.getElementsByTagName('input')[1]
    if (hideHeaderCheckBox.type == 'checkbox') {
      hideHeaderCheckBox.type = 'hidden'
    }
  }
  else {
    header.css('display', 'none')
  }
  if (!scroll) {
    window.scrollTo(0, ((route || {}).params || {}).backScroll || 0)
  }
}

export default headers
