import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.css'
import './assets/css/AdminLTE.min.css'
import './assets/js/adminlte.min.js'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import resourceActions from './resourceActions.js'
import Datepicker from 'vuejs-datepicker'
import VueEvents from 'vue-events'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import Vuex from 'vuex'
import VtCss from './components/mixins/VueTableData'
import TableLoadMsg from './components/mixins/TableLoadMsg'
import ShowByUserRoles from './components/mixins/ShowByUserRoles'
import ShowEntries from './components/app/showEntries.vue'
import fixedHeadersFunction from './components/mixins/fixedHeadersFunction.js'
import VueUploadComponent from 'vue-upload-component'
import ElementUI from 'element-ui'
import VeeValidate from 'vee-validate'
import VueLodash from 'vue-lodash'
import * as uiv from 'uiv'
import './components/multilanguage/ml'

Vue.use(uiv)
Vue.component('v-date-picker', Datepicker)
Vue.component('v-show-entries', ShowEntries)
Vue.component('file-upload', VueUploadComponent)
Vue.use(VueEvents)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(Vuex)
Vue.mixin(VtCss)
Vue.mixin(ShowByUserRoles)
Vue.mixin(TableLoadMsg)
Vue.use(ElementUI)

const config = {
  fieldsBagName: 'validationFields'
}

Vue.use(VeeValidate, config)
Vue.use(VueLodash)

window.rootUrl = '/'
window.resource = Vue.resource('', {}, resourceActions)
window.fixedHeadersFunc = fixedHeadersFunction

const token = localStorage.getItem('token')
if (token) {
  // Vue.http.headers.common['Authorization'] = 'Bearer ' + token
  // Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  Vue.http.interceptors.push(function (request, next) {
    request.headers.set('Authorization', 'Bearer ' + token)
    next(response => {
      if (response.status === 401) {
        router.push('/login')
      }
    })
  })
}

Vue.config.productionTip = false

new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app-root')
