<template>
  <component :is="layout">
    <router-view :layout.sync="layout" />
  </component>
</template>

<script>
import Vue from 'vue'

Vue.mixin({
  computed: {
    permissions () {
      return this.$store.getters.getPermissions
    },
    user () {
       return this.$store.getters.fetchUser
    }
  }
})

export default {
  name: `App`,
  data () {
    return {
      layout: `div`
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    user () {
       return this.$store.getters.fetchUser
    }
  },
  created () {
    console.log('App created')
    if (this.isLoggedIn) {
      this.$store.dispatch('fetchGetUser')
      this.$store.dispatch('getPermissions')
    }
  },
  mounted () {
    console.log('App mounted')
  },
  methods: {

  }
}
</script>

<style>
    body:has(div.login-page) {
        background-color: #d2d6de;
    }
    .v-select {
      width: 120px;
      font-size: 14px;
      background-color: white;
    }
    .v-select .vs__open-indicator {
      transform: scale(0.7);
    }
    .v-select.vs--open .vs__open-indicator {
      transform: rotate(180deg) scale(0.7);
    }
    .edit-page-department-dropdown.v-select .vs__dropdown-toggle {
      padding: 0;
    }
    .edit-page-department-dropdown.v-select .vs__dropdown-menu {
      min-width: 250px;
    }
    .edit-page-department-dropdown.v-select .vs__selected-options {
      padding: 0;
      white-space: nowrap;
      max-height: 20px;
    }
    .edit-page-department-dropdown.v-select .vs__search, 
    .edit-page-department-dropdown.v-select .edit-page-department-dropdown.vs__search:focus, 
    .edit-page-department-dropdown.v-select .vs__selected {
      margin-top: 0;
    }
    .edit-page-department-dropdown.v-select .vs__actions {
      cursor: pointer;
    }
    .edit-page-department-dropdown.v-select.vs--disabled .vs__search {
      background-color: transparent;
    }

    .problem-add-page-dropdown.v-select .vs__dropdown-toggle {
      padding: 0;
    }
    .problem-add-page-dropdown.v-select .vs__dropdown-menu {
      min-width: 250px;
    }
    .problem-add-page-dropdown.v-select .vs__selected-options {
      padding: 3px 0;
      white-space: nowrap;
      max-height: 28px;
    }
    .problem-add-page-dropdown.v-select .vs__search, 
    .problem-add-page-dropdown.v-select .vs__search:focus, 
    .problem-add-page-dropdown.v-select .vs__selected {
      margin-top: 0;
    }
    .problem-add-page-dropdown.v-select .vs__actions {
      cursor: pointer;
    }
    .problem-add-page-dropdown.v-select.vs--disabled .vs__search {
      background-color: transparent;
    }

    .return-edit-page-dropdown.v-select .vs__dropdown-toggle {
      padding: 0;
    }
    .return-edit-page-dropdown.v-select .vs__dropdown-menu {
      min-width: 250px;
    }
    .return-edit-page-dropdown.v-select .vs__selected-options {
      padding: 0;
      white-space: nowrap;
      max-height: 20px;
    }
    .return-edit-page-dropdown.v-select .vs__search, 
    .return-edit-page-dropdown.v-select .edit-page-department-dropdown.vs__search:focus, 
    .return-edit-page-dropdown.v-select .vs__selected {
      margin-top: 0;
    }
    .return-edit-page-dropdown.v-select .vs__actions {
      cursor: pointer;
    }
    .return-edit-page-dropdown.v-select.vs--disabled .vs__search {
      background-color: transparent;
    }

    .return-add-page-dropdown.v-select .vs__dropdown-toggle {
      padding: 0;
    }
    .return-add-page-dropdown.v-select .vs__dropdown-menu {
      min-width: 250px;
      font-size: 12px;
    }
    .return-add-page-dropdown.v-select .vs__selected-options {
      padding: 5px 0;
      white-space: nowrap;
      max-height: 28px;
      font-size: 12px;
    }
    .return-add-page-dropdown.v-select .vs__search, 
    .return-add-page-dropdown.v-select .vs__search:focus, 
    .return-add-page-dropdown.v-select .vs__selected {
      margin-top: 0;
    }
    .return-add-page-dropdown.v-select .vs__actions {
      cursor: pointer;
    }
    .return-add-page-dropdown.v-select.vs--disabled .vs__search {
      background-color: transparent;
    }
    .activity-filter {
      position: relative;
    }
    .activity-filter .multiselect {
      width: 84%;
      position: absolute;
      float: left;
      min-height: 36px;
    }
    .activity-filter .multiselect .multiselect__input {
      font-size: 14px;
      margin-top: 2px;
    }
    .activity-filter .multiselect .multiselect__select {
      height: 34px;
    }
    .activity-filter .multiselect .multiselect__content-wrapper {
      width: 130%;
      border: 1px solid #e8e8e8;
    }
    .activity-filter .multiselect .multiselect__tags {
      min-height: 36px;
      padding-top: 4px;
    }
    .activity-filter .multiselect .multiselect__tags .multiselect__single {
      font-size: 14px;
      margin-bottom: 6px;
      margin-top: 4px;
    }
    .activity-filter .multiselect.multiselect--active .multiselect__tags { 
      border-bottom: none;
    }
    .activity-filter .multiselect .multiselect__element {
      font-size: 14px;
    }
    .activity-filter .multiselect .multiselect__tags .multiselect__placeholder {
      margin-bottom: 6px;
    }
    .vdp-datepicker .roundedInput {
      background-color: white;
    }
</style>
